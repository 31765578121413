<!--

  File: HourAssignmentPlanModal.vue

  Defines a component that allows managers to create an hour assignment plan
  and assign it to users working on a task.

  Props:
    None

  Events:
    close: Emitted when the user wants to close the window. Event argument indicates whether
           data has been modified and the parent should refetch the data.
    store-undo: Event to signal to parent component to save the returned undo data.

-->
<template>
  <section>
    <b-modal
      :active="isActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 1100px">
        <header class="modal-card-head">
          <p class="modal-card-title">Hour Assignment Plan</p>
          <HelpDoc id="hour-assignment-plan-modal" />
          <button
            type="button"
            class="delete"
            @click="$emit('close', { modified: false })"
          />
        </header>
        <section class="modal-card-body">
          <section class="tile is-vertical mb-4" v-if="task.Id">
            <p class="subtitle has-text-primary my-0">
              {{ task.Id }} ({{ task.AccountingId }})
            </p>
            <p class="subtitle has-text-primary my-0">
              POP: {{ task.StartPOP }} to {{ task.EndPOP }}
            </p>
            <p class="subtitle has-text-primary my-0">
              {{ task.fundingSourceType.Id }}
            </p>
          </section>
          <section class="block">
            <b-table :data="hourAssignmentPlan.parts">
              <b-table-column field="Units" label="Units" centered>
                <template v-slot="props">
                  <b-select
                    @input="modified = true"
                    v-model="props.row.Units"
                    placeholder="Select units"
                  >
                    <option value="TOTAL_HOURS">Total Hours</option>
                    <option value="HOURS">Hours</option>
                    <option value="FTE">FTE</option>
                  </b-select>
                </template>
              </b-table-column>
              <b-table-column field="Assignment" label="Assignment" centered>
                <template v-slot="props">
                  <b-numberinput
                    type="is-primary"
                    controls-alignment="center"
                    size="is-small"
                    controls-position="compact"
                    controls-rounded
                    min="0"
                    :max="props.row.Units === 'FTE' ? '1.5' : '96'"
                    step="0.25"
                    @input="modified = true"
                    v-model="props.row.Assignment"
                  ></b-numberinput>
                </template>
              </b-table-column>
              <b-table-column
                field="FTEBaseHours"
                label="FTE Hours / Year"
                centered
              >
                <template v-slot="props">
                  <b-numberinput
                    :disabled="props.row.Units !== 'FTE'"
                    type="is-primary"
                    controls-alignment="center"
                    size="is-small"
                    controls-position="compact"
                    controls-rounded
                    min="0"
                    max="2087"
                    step="1"
                    @input="modified = true"
                    v-model="props.row.FTEBaseHours"
                  ></b-numberinput>
                </template>
              </b-table-column>
              <b-table-column
                field="OffPlanStrategy"
                label="Off Plan Strategy"
                centered
              >
                <template v-slot="props">
                  <b-select
                    v-model="props.row.OffPlanStrategy"
                    placeholder="Select a strategy"
                    @input="modified = true"
                  >
                    <option value="IGNORE">Ignore</option>
                    <option value="REDISTRIBUTE">Redistribute</option>
                  </b-select>
                </template>
              </b-table-column>
              <b-table-column
                field="startPayPeriod.StartDate"
                label="Start"
                centered
              >
                <template v-slot="props">
                  <b-datepicker
                    v-model="props.row.startPayPeriod.StartDate"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @input="modified = true"
                    editable
                    trap-focus
                    :date-formatter="
                      (d) => {
                        return d.toISOString().substring(0, 10);
                      }
                    "
                    :date-parser="
                      (s) => {
                        return new Date(s);
                      }
                    "
                  >
                  </b-datepicker>
                </template>
              </b-table-column>
              <b-table-column field="endPayPeriod.EndDate" label="End" centered>
                <template v-slot="props">
                  <b-datepicker
                    v-model="props.row.endPayPeriod.EndDate"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @input="modified = true"
                    editable
                    trap-focus
                    :date-formatter="
                      (d) => {
                        return d.toISOString().substring(0, 10);
                      }
                    "
                    :date-parser="
                      (s) => {
                        return new Date(s);
                      }
                    "
                  >
                  </b-datepicker>
                </template>
              </b-table-column>
              <b-table-column>
                <template v-slot="props">
                  <b-button
                    v-if="props.index === hourAssignmentPlan.parts.length - 1"
                    type="is-primary"
                    size="is-normal"
                    icon-right="keyboard-return"
                    @click.native="addRow()"
                  />
                  <b-button
                    v-else
                    type="is-primary"
                    size="is-normal"
                    icon-right="delete-forever"
                    @click.native="deleteRow(props.index)"
                  />
                </template>
              </b-table-column>
            </b-table>
          </section>
          <section class="block mt-5">
            <b-field label="Included employees">
              <b-taginput
                v-model="includedEmployees"
                :data="filteredEmployees"
                autocomplete
                :open-on-focus="true"
                field="Name"
                icon="label"
                placeholder="Add an employee"
                @typing="getFilteredTags"
              >
              </b-taginput>
            </b-field>
          </section>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-primary"
            type="button"
            :disabled="!modified"
            @click="savePlan()"
          >
            Save
          </button>
        </footer>
      </div>
      <b-loading
        :is-full-page="false"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </b-modal>
  </section>
</template>
<script>
import HelpDoc from "@/components/HelpDoc";
import gql from "graphql-tag";
export default {
  props: {
    isActive: {
      type: Boolean,
    },
    assignments: {
      type: Array,
    },
    task: {
      type: Object,
    },
  },
  components: { HelpDoc },
  data() {
    return {
      isLoading: false,
      modified: false,
      includedEmployees: [],
      employeeChoices: [],
      filteredEmployees: [],
      hourAssignmentPlan: {},
      minDate: null,
      maxDate: null,
    };
  },
  watch: {
    isActive(newActive) {
      if (!newActive) {
        return;
      }

      let start = this.assignments[0].StartDate;
      start += " 00:00:00";
      this.minDate = new Date(start);
      let end = this.assignments[0].EndDate;
      end += " 00:00:00";
      this.maxDate = new Date(end);

      // Use JSON to deep copy the plan.
      this.hourAssignmentPlan = JSON.parse(
        JSON.stringify(this.assignments[0].hourAssignmentPlan),
      );
      // The datepicker control requires Date objects.
      if (this.hourAssignmentPlan) {
        for (const p of this.hourAssignmentPlan.parts) {
          let s = p.startPayPeriod.StartDate;
          p.startPayPeriod.StartDate = new Date(s + " 00:00:00");
          s = p.endPayPeriod.EndDate;
          p.endPayPeriod.EndDate = new Date(s + " 00:00:00");
        }
        this.addRow();
        this.modified = false;
      } else {
        let start = new Date();
        let end = new Date(this.assignments[0].EndDate + " 00:00:00");
        this.hourAssignmentPlan = {
          LaborCategoryPkey: this.assignments[0].LaborCategoryPkey,
          parts: [
            {
              Units: "FTE",
              Assignment: 1,
              startPayPeriod: { StartDate: start },
              endPayPeriod: { EndDate: end },
              FTEBaseHours: 1920,
              OffPlanStrategy: "REDISTRIBUTE",
            },
          ],
        };
        this.modified = true;
      }
      this.includedEmployees = this.assignments.map((x) => {
        return { EmployeePkey: x.EmployeePkey, Name: x.Name };
      });
      this.employeeChoices = this.task.laborHourSummary.map((x) => {
        return { EmployeePkey: x.EmployeePkey, Name: x.Name };
      });
      this.filteredEmployees = this.employeeChoices;
    },
  },
  computed: {},
  methods: {
    getFilteredTags(text) {
      this.filteredEmployees = this.employeeChoices.filter((option) => {
        return (
          option.Name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
    },
    deleteRow(rowindex) {
      this.hourAssignmentPlan.parts.splice(rowindex, 1);
      this.modified = true;
    },
    addRow() {
      let r = {
        Units: null,
        Assignment: null,
        startPayPeriod: { StartDate: new Date() },
        endPayPeriod: { EndDate: null },
        FTEBaseHours: 1920,
        OffPlanStrategy: "REDISTRIBUTE",
      };
      this.hourAssignmentPlan.parts.push(r);
      this.modified = true;
    },
    createUndoRecord() {
      var parts = this.hourAssignmentPlan.parts.filter(
        (x) => x.endPayPeriod.EndDate,
      );
      var hapInput = this.includedEmployees.map((x) => {
        return {
          EmployeePKEY: x.EmployeePkey,
          LaborCategoryPKEY: this.hourAssignmentPlan.LaborCategoryPkey,
          parts: parts.map((p) => {
            return {
              Units: p.Units,
              Assignment: p.Assignment,
              Start: p.startPayPeriod.StartDate.toISOString().substring(0, 10),
              End: p.endPayPeriod.EndDate.toISOString().substring(0, 10),
              FTEBaseHours: p.FTEBaseHours,
              OffPlanStrategy: p.OffPlanStrategy,
            };
          }),
        };
      });
      var undo = this.assignments.map((x) => {
        return {
          EmployeePKEY: x.EmployeePkey,
          LaborCategoryPKEY: x.LaborCategoryPkey,
          parts: x.hourAssignmentPlan
            ? x.hourAssignmentPlan.parts.map((p) => {
                return {
                  Units: p.Units,
                  Assignment: p.Assignment,
                  Start: p.startPayPeriod.StartDate,
                  End: p.endPayPeriod.EndDate,
                  FTEBaseHours: p.FTEBaseHours,
                  OffPlanStrategy: p.OffPlanStrategy,
                };
              })
            : null,
        };
      });
      return { action: hapInput, inverseAction: undo };
    },
    savePlan() {
      this.isLoading = true;
      var undorec = this.createUndoRecord();
      var hapInput = undorec.action;
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($HAPlan: [HourAssignmentPlanInput]) {
              addOrReplaceHourAssignmentPlan(input: $HAPlan) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            HAPlan: hapInput,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Updated hour assignment plan!",
            type: "is-success",
          });
          this.isLoading = false;
          this.$emit("store-undo", undorec);
          this.$emit("close", { modified: true });
        })
        .catch((error) => {
          // Error
          console.error(error);
          this.$buefy.snackbar.open({
            message:
              "Failed to update hour assignment plans. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
          this.isLoading = false;
          this.$emit("close", { modified: false });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .animation-content .modal-card {
  overflow: visible !important;
}

::v-deep .modal-card-body {
  overflow: visible !important;
}

::v-deep .table-wrapper {
  overflow-x: visible !important;
  overflow-y: visible !important;
}
</style>
