<!--

  File: ContractTracking.vue

  Defines a component that displays the logged in user's portfolio. Summary information
  for each task in the portfolio is displayed in a table.

  Props:
    id: Employee PKEY
    role: Role of the signed in user

  Events:
    None

-->
<template>
  <div>
    <div class="columns is-gapless is-align-items-baseline">
      <div class="column">
        <div class="buttons">
          <b-tooltip
            type="is-info"
            label="Refresh"
            position="is-right"
            :delay="500"
          >
            <b-button
              class="button is-primary mr-1"
              size="is-normal"
              icon-left="refresh"
              @click="refresh()"
            />
          </b-tooltip>
          <b-tooltip
            type="is-info"
            label="Show/hide columns"
            position="is-right"
            :delay="500"
          >
            <b-dropdown
              aria-role="dialog"
              :close-on-click="false"
              @active-change="columnActivity"
            >
              <template #trigger>
                <b-button
                  class="button is-primary mr-1"
                  size="is-normal"
                  icon-left="format-columns"
                />
              </template>
              <b-dropdown-item
                v-for="(c, i) in columnSettings"
                :key="i"
                aria-role="listitem"
              >
                <b-checkbox v-model="c.visible">
                  {{ c.label }}
                </b-checkbox>
              </b-dropdown-item>
            </b-dropdown>
          </b-tooltip>
          <b-tooltip
            type="is-info"
            label="Filter options"
            position="is-right"
            :delay="500"
          >
            <b-dropdown aria-role="dialog" :close-on-click="false">
              <template #trigger>
                <b-button
                  class="button is-primary mr-1"
                  size="is-normal"
                  icon-left="filter"
                />
              </template>
              <b-dropdown-item aria-role="listitem">
                <b-checkbox v-model="expiredTasksHidden">
                  Hide expired tasks
                </b-checkbox>
              </b-dropdown-item>
            </b-dropdown>
          </b-tooltip>
          <b-tooltip
            type="is-info"
            label="Download data as comma separated values"
            position="is-right"
            :delay="500"
          >
            <b-button
              class="button is-primary mr-1"
              size="is-normal"
              icon-left="download"
              @click="downloadCsv()"
            />
          </b-tooltip>
          <HourAssignmentPlanUndo
            @interface="storeHourAssignmentPlanUndoInterface($event)"
            @request-refresh="refresh()"
          />
        </div>
      </div>
      <div class="column">
        <h1 class="title has-text-primary has-text-centered my-0">
          Contract Tracking
        </h1>
        <h2
          class="subtitle has-text-primary has-text-centered my-0"
          v-if="lastPayPeriodUploaded"
        >
          {{ lastPayPeriodUploaded.EndDate }} is the end date of last pay period
          uploaded
        </h2>
      </div>
      <div class="column has-text-right">
        <HelpDoc id="contract-tracking" />
      </div>
    </div>
    <b-table
      class="my-0"
      :data="portfolio"
      ref="trackingTable"
      default-sort-direction="asc"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      :loading="$apollo.loading"
      sticky-header
      detailed
      :detail-transition="detailTransitionName"
      @details-open="detailsOpen($event)"
      @details-close="openRow = undefined"
      :height="pageHeight()"
      :row-class="rowStatus"
    >
      <template #empty>
        <div class="hero is-danger">
          <div class="hero-body">
            <h1 class="subtitle">
              There are no tasks in your portfolio. Contact someone in finance
              to assign tasks to you.
            </h1>
          </div>
        </div>
      </template>
      <b-table-column
        :field="columnSettings[0].field"
        :label="columnSettings[0].label"
        :visible="columnSettings[0].visible"
        sortable
        searchable
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Search..."
            icon="magnify"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span style="white-space: nowrap">{{ props.row.task.Id }}</span>
        </template>
      </b-table-column>
      <b-table-column
        :field="columnSettings[1].field"
        :label="columnSettings[1].label"
        :visible="columnSettings[1].visible"
        sortable
        searchable
      >
        <template #searchable="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Search..."
            icon="magnify"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          {{ props.row.task.AccountingId }}
        </template>
      </b-table-column>
      <b-table-column
        :field="columnSettings[2].field"
        :label="columnSettings[2].label"
        :visible="columnSettings[2].visible"
        numeric
        v-slot="props"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(props.row.Ceiling)
        }}
      </b-table-column>
      <b-table-column
        :field="columnSettings[3].field"
        :label="columnSettings[3].label"
        :visible="columnSettings[3].visible"
        numeric
        v-slot="props"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(props.row.Funded)
        }}
      </b-table-column>
      <b-table-column
        :field="columnSettings[4].field"
        :label="columnSettings[4].label"
        :visible="columnSettings[4].visible"
        numeric
        v-slot="props"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(props.row.Fee)
        }}
      </b-table-column>
      <b-table-column
        :field="columnSettings[5].field"
        :label="columnSettings[5].label"
        :visible="columnSettings[5].visible"
        numeric
        v-slot="props"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(props.row.ReservedSubcontract)
        }}
      </b-table-column>
      <b-table-column
        :field="columnSettings[6].field"
        :label="columnSettings[6].label"
        :visible="columnSettings[6].visible"
        numeric
        v-slot="props"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(props.row.OverageSubcontract)
        }}
      </b-table-column>
      <b-table-column
        :field="columnSettings[7].field"
        :label="columnSettings[7].label"
        :visible="columnSettings[7].visible"
        numeric
        v-slot="props"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(props.row.ReservedODC)
        }}
      </b-table-column>
      <b-table-column
        :field="columnSettings[8].field"
        :label="columnSettings[8].label"
        :visible="columnSettings[8].visible"
        numeric
        v-slot="props"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(props.row.OverageODC)
        }}
      </b-table-column>
      <b-table-column
        :field="columnSettings[9].field"
        :label="columnSettings[9].label"
        :visible="columnSettings[9].visible"
        numeric
        v-slot="props"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(props.row.Used)
        }}
      </b-table-column>
      <b-table-column
        :field="columnSettings[10].field"
        :label="columnSettings[10].label"
        :visible="columnSettings[10].visible"
        numeric
        v-slot="props"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(props.row.Available)
        }}
      </b-table-column>
      <b-table-column
        :field="columnSettings[11].field"
        :label="columnSettings[11].label"
        :visible="columnSettings[11].visible"
        numeric
        v-slot="props"
      >
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(props.row.Budgeted)
        }}
      </b-table-column>
      <b-table-column
        :field="columnSettings[12].field"
        :label="columnSettings[12].label"
        :visible="columnSettings[12].visible"
        numeric
        v-slot="props"
      >
        <span class="hero" :class="taskStatus(props)">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.row.Remaining)
          }}
        </span>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <b-tabs type="m-0 p-0">
          <b-tab-item label="Hour Assignments" icon="clock">
            <TaskAssignments
              :taskId="props.row.task.id"
              @request-refresh="refresh()"
              @store-undo="undo.push($event)"
              @interface="storeTaskAssignmentsInterface"
            />
          </b-tab-item>
          <b-tab-item label="Portfolio" icon="briefcase">
            <PortfolioInput
              :taskId="props.row.task.id"
              @request-refresh="refresh()"
            />
          </b-tab-item>
          <b-tab-item label="Subcontracts" icon="account-hard-hat">
            <SubcontractSummary :taskId="props.row.task.id" />
          </b-tab-item>
          <b-tab-item label="ODCs" icon="airplane">
            <OdcExpenditure :taskId="props.row.task.id" />
          </b-tab-item>
          <b-tab-item label="Expended Hours" icon="home-account">
            <ExpendedHours :taskId="props.row.task.id" :fullWidth="true" />
          </b-tab-item>
          <b-tab-item label="Assigned Hours" icon="clock-plus">
            <AssignedHours
              :taskId="props.row.task.id"
              @interface="storeAssignedHoursInterface"
            />
          </b-tab-item>
          <b-tab-item label="Info" icon="information">
            <TaskInfo :taskId="props.row.task.id" />
          </b-tab-item>
        </b-tabs>
      </template>
    </b-table>
  </div>
</template>

<script>
import gql from "graphql-tag";
import AssignedHours from "./AssignedHours.vue";
import ExpendedHours from "./ExpendedHours.vue";
import HelpDoc from "./HelpDoc";
import HourAssignmentPlanUndo from "./HourAssignmentPlanUndo.vue";
import PortfolioInput from "./PortfolioInput.vue";
import TaskAssignments from "@/components/TaskAssignments";
import SubcontractSummary from "@/components/SubcontractSummary";
import OdcExpenditure from "@/components/OdcExpenditure";
import TaskInfo from "@/components/TaskInfo";

export default {
  props: ["id", "role"],
  components: {
    AssignedHours,
    ExpendedHours,
    HelpDoc,
    HourAssignmentPlanUndo,
    OdcExpenditure,
    PortfolioInput,
    SubcontractSummary,
    TaskAssignments,
    TaskInfo,
  },
  apollo: {
    // Vue-Apollo options here
    lastPayPeriodUploaded: {
      fetchPolicy: "no-cache",
      query: gql`
        query {
          lastPayPeriodUploaded {
            EndDate
          }
        }
      `,
    },
    storedColumnSettings: {
      query: gql`
        query ($userPkey: ID!) {
          allEmployees(filters: { PKEY: $userPkey }) {
            edges {
              node {
                columnSettings {
                  ColumnIndex
                  Visible
                }
              }
            }
          }
        }
      `,
      variables() {
        // Use vue reactive properties here
        return {
          userPkey: this.id,
        };
      },
      update(data) {
        // return the portfolio, filter should return a single edge
        return data.allEmployees.edges[0].node.columnSettings;
      },
      result() {
        this.storedColumnSettings.forEach((cs) => {
          this.columnSettings[cs.ColumnIndex].visible = cs.Visible;
        });
      },
    },
  },
  created() {
    if (this.role === "MANAGER") {
      this.$apollo.addSmartQuery("portfolio", {
        fetchPolicy: "no-cache",
        query: gql`
          query ($userPkey: ID!) {
            allEmployees(filters: { PKEY: $userPkey }) {
              edges {
                node {
                  portfolio(onlyOpen: true) {
                    task {
                      laborCostSummary {
                        task {
                          id
                          PKEY
                          Id
                          AccountingId
                          EndPOP
                        }
                        Ceiling
                        Funded
                        Fee
                        ReservedSubcontract
                        OverageSubcontract
                        ReservedODC
                        OverageODC
                        Used
                        Available
                        Budgeted
                        Remaining
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables() {
          // Use vue reactive properties here
          return {
            userPkey: this.id,
          };
        },
        update(data) {
          // return the portfolio, filter should return a single edge
          return data.allEmployees.edges[0].node.portfolio.map(
            (x) => x.task.laborCostSummary,
          );
        },
        result() {
          this.reopenRow();
        },
      });
    }
    if (this.role === "ADMIN") {
      this.$apollo.addSmartQuery("portfolio", {
        fetchPolicy: "no-cache",
        query: gql`
          {
            portfolio: laborCostSummary {
              task {
                id
                PKEY
                Id
                AccountingId
                EndPOP
              }
              Ceiling
              Funded
              Fee
              ReservedSubcontract
              OverageSubcontract
              ReservedODC
              OverageODC
              Used
              Available
              Budgeted
              Remaining
            }
          }
        `,
        result() {
          this.reopenRow();
        },
      });
    }
  },
  data() {
    return {
      detailTransitionName: "fade",
      openRow: undefined,
      portfolio: [],
      expiredTasksHidden: false,
      columnSettings: [
        { field: "task.Id", label: "Id", visible: true },
        { field: "task.AccountingId", label: "Charge Number", visible: true },
        {
          field: "Ceiling",
          label: "Ceiling",
          visible: true,
        },
        {
          field: "Funded",
          label: "Funded",
          visible: true,
        },
        { field: "Fee", label: "Fee", visible: true },
        {
          field: "ReservedSubcontract",
          label: "Subcontract Reserved",
          visible: true,
        },
        {
          field: "OverageSubcontract",
          label: "Subcontract Overage",
          visible: true,
        },
        {
          field: "ReservedODC",
          label: "ODC Reserved",
          visible: true,
        },
        {
          field: "OveragedODC",
          label: "ODC Overage",
          visible: true,
        },
        { field: "Used", label: "Labor Used", visible: true },
        {
          field: "Available",
          label: "Labor Available",
          visible: true,
        },
        {
          field: "Budgeted",
          label: "Labor Budgeted",
          visible: true,
        },
        {
          field: "Remaining",
          label: "Labor Remaining",
          visible: true,
        },
      ],
    };
  },
  computed: {},
  methods: {
    rowStatus(row) {
      var today = new Date();
      var endPOP = new Date(row.task.EndPOP + " 00:00:00");
      if (today > endPOP) {
        if (this.expiredTasksHidden) return "is-hidden";
        return "has-background-info";
      }
      return "";
    },
    downloadCsv() {
      var header = this.columnSettings.map((x) => '"' + x.label + '"').join();
      var csv = this.portfolio
        .map((x) =>
          this.columnSettings
            .map((c, i) => {
              if (i < 2) return '"' + x.task[c.field.split(".")[1]] + '"';
              if (x[c.field]) return x[c.field].toFixed(2);
              return 0.0;
            })
            .join(),
        )
        .join("\n");
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," +
        encodeURIComponent(header + "\n" + csv);
      anchor.target = "_blank";
      anchor.download = "contract-tracking.csv";
      anchor.click();
    },
    pageHeight() {
      return (
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 170
      );
    },
    storeAssignedHoursInterface(i) {
      this.assignedHours = i;
    },
    storeHourAssignmentPlanUndoInterface(i) {
      this.undo = i;
    },
    storeTaskAssignmentsInterface(i) {
      this.taskAssignments = i;
    },
    reopenRow() {
      if (this.openRow) {
        // reopen details when data is refetched
        let openThis = this.portfolio.find(
          (e) => e.task.PKEY === this.openRow.task.PKEY,
        );
        this.openRow = openThis;
        this.$refs.trackingTable.openDetailRow(openThis);
      }
    },
    detailsOpen(row) {
      if (this.openRow) {
        this.$refs.trackingTable.closeDetailRow(this.openRow);
      }
      this.openRow = row;
    },
    taskStatus(props) {
      let r = props.row.Remaining;
      let closeEnough = props.row.Funded * 0.005;
      if (r < -closeEnough) {
        return "is-danger";
      }
      if (-closeEnough < r && r < closeEnough) {
        return "is-success";
      }
      return "";
    },
    refresh() {
      this.$apollo.queries.portfolio.refetch();
      this.assignedHours.refresh();
      this.taskAssignments.refresh();
    },
    columnActivity(active) {
      if (active) {
        this.columnSettings.forEach((e) => {
          e.saved = e.visible;
        });
        return;
      }
      let modified = this.columnSettings.reduce((accum, e) => {
        return accum || e.visible != e.saved;
      }, false);
      if (!modified) return;
      // save column settings
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($EmployeePkey: Int!, $settings: [ColumnSetting!]!) {
              updateColumnVisibility(
                input: { EmployeePkey: $EmployeePkey, settings: $settings }
              ) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            EmployeePkey: this.id,
            settings: this.columnSettings.map((x, i) => {
              return { index: i, visible: x.visible };
            }),
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Updated column settings!",
            type: "is-success",
          });
        })
        .catch((error) => {
          // Error
          console.error(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep tr.detail {
  background-color: unset !important;
}
</style>
