<!--

  File: TaskModal.vue

  Defines a component to display a tabbed modal overlay for modifying
  task information. Each tab contains specific data, for example:
  task info, labor categories, subcontracts and ODCs.

  Props:
    isActive: If true, causes the modal to display, false hides the modal.
    projectPkey: The primary key to set as the ProjectPKEY of new tasks.
    task: The object to modify.

  Events:
    close: Emitted when one of the buttons to close the modal is pressed.
           It is up to the parent component to set isActive to false to
           hide the modal.
    modified: Emitted if the task information has been modified. The parent
              should set isActive to false to hide the modal.

-->
<template>
  <section>
    <b-modal
      :active="isActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
      width="1200"
    >
      <div class="modal-card" style="width: 1100px">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="task">
            Edit Task: {{ task.AccountingId }} - {{ task.Id }}
          </p>
          <p class="modal-card-title" v-else>New Task</p>
          <HelpDoc id="task-modal" />
          <button type="button" class="delete" @click="closeForm()" />
        </header>
        <section class="modal-card-body">
          <b-tabs v-model="activeTab">
            <b-tab-item label="Task Info" icon="clipboard-text">
              <TaskForm
                :projectPkey="projectPkey"
                :taskId="taskId"
                ref="TaskForm"
                @modified="$emit('modified')"
                @updated="handleUpdated()"
              />
            </b-tab-item>
            <b-tab-item
              label="Labor Categories"
              icon="clock"
              :disabled="!taskId"
            >
              <LaborCategories :taskId="taskId" ref="LaborCategories" />
            </b-tab-item>
            <b-tab-item
              label="Labor Category Assignments"
              icon="clock-outline"
              :disabled="!taskId"
            >
              <LaborCategoryAssignments :taskId="taskId" />
            </b-tab-item>
            <b-tab-item
              label="Subcontracts"
              icon="account-hard-hat"
              :disabled="!taskId"
            >
              <SubcontractForm :taskId="taskId" />
            </b-tab-item>
            <b-tab-item label="ODCs" icon="airplane" :disabled="!taskId">
              <OdcForm :taskId="taskId" />
            </b-tab-item>
            <b-tab-item
              label="Expended Hours"
              icon="home-account"
              :disabled="!taskId"
            >
              <ExpendedHours :taskId="taskId" />
            </b-tab-item>
          </b-tabs>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ExpendedHours from "./ExpendedHours.vue";
import HelpDoc from "./HelpDoc";
import TaskForm from "./TaskForm";
import LaborCategories from "./LaborCategories";
import LaborCategoryAssignments from "./LaborCategoryAssignments";
import OdcForm from "@/components/OdcForm";
import SubcontractForm from "@/components/SubcontractForm";
import gql from "graphql-tag";

export default {
  components: {
    ExpendedHours,
    LaborCategoryAssignments,
    LaborCategories,
    TaskForm,
    HelpDoc,
    OdcForm,
    SubcontractForm,
  },
  props: {
    isActive: { type: Boolean },
    projectPkey: { type: String },
    taskId: { type: String },
  },
  apollo: {
    // Vue-Apollo options here
    task: {
      query: gql`
        query ($id: ID!) {
          task(id: $id) {
            id
            Id
            AccountingId
          }
        }
      `,
      variables() {
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
  watch: {
    taskId(newId) {
      if (!newId) {
        this.activeTab = 0;
      }
    },
    isActive(newIsActive) {
      if (newIsActive) {
        this.$refs.TaskForm.resetTask();
      }
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {},
  methods: {
    closeForm() {
      this.$emit("close");
    },
    handleUpdated() {
      Object.values(this.$refs.TaskForm.$apollo.queries).forEach((e) =>
        e.refetch(),
      );
      Object.values(this.$refs.LaborCategories.$apollo.queries).forEach((e) =>
        e.refetch(),
      );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .animation-content .modal-card {
  overflow: visible !important;
}

::v-deep .modal-card-body {
  overflow: visible !important;
}
</style>
