<!--

  File: ExpendedHoursTable.vue

  Defines a component that displays in a table the number of hours
  expended by pay period for employees that have charged against the
  given task.

  Props:
    taskId: The actual hours expended is displayed for this task.
    fullScreen: Boolean to indicate component is being displayed in
        full screen mode.

  Events:
    None.

-->
<template>
  <div :style="fullScreen ? 'margin-top:70px;' : ''">
    <b-table
      :data="expendedHours"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      :loading="$apollo.loading"
      :default-sort="['name', 'asc']"
      focusable
      sticky-header
      :height="tableHeight(expendedHours)"
    >
      <template #empty>
        <div class="hero is-danger">
          <div class="hero-body">
            <h1 class="subtitle">No hours expended on this task.</h1>
          </div>
        </div>
      </template>
      <b-table-column
        v-for="(c, i) in columns"
        :key="i"
        :label="c.label"
        :field="c.field"
        :sortable="c.sortable"
        :numeric="c.numeric"
        :sticky="c.sticky"
      >
        <template v-slot:header="{ column }">
          <span style="white-space: nowrap">
            {{ column.label }}
          </span>
        </template>
        <template v-slot="props">
          <span style="white-space: nowrap">
            {{ props.row[c.field] }}
          </span>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
import gql from "graphql-tag";

export default {
  props: ["taskId", "fullScreen"],
  apollo: {
    expendedHours: {
      query: gql`
        query ($task: ID!) {
          expendedHours: task(id: $task) {
            id
            actualLabor {
              edges {
                node {
                  Hours
                  payPeriod {
                    PKEY
                    EndDate
                  }
                  employee {
                    Name
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          task: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
      update(data) {
        // pivot data so that there is a column for each pay period
        var pivot = {};
        data.expendedHours.actualLabor.edges.forEach((e) => {
          var name = e.node.employee.Name;
          var pp = e.node.payPeriod.EndDate;
          var hours = e.node.Hours;
          var ppkey = e.node.payPeriod.PKEY;
          pivot[name] = pivot[name] || { name: name };
          pivot[name]["maxPP"] = pivot[name]["maxPP"] || -Infinity;
          pivot[name]["minPP"] = pivot[name]["minPP"] || Infinity;
          pivot[name][pp] = pivot[name][pp] || 0;
          pivot[name]["maxPP"] = Math.max(pivot[name]["maxPP"], ppkey);
          pivot[name]["minPP"] = Math.min(pivot[name]["minPP"], ppkey);
          pivot[name][pp] += hours;
        });
        return Object.values(pivot).sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      },
      result() {
        this.fillInMissingHours();
      },
    },
    columns: {
      query: gql`
        query ($ppStart: ID!, $ppEnd: ID!) {
          columns: allPayPeriods(
            filters: { PKEYGte: $ppStart, PKEYLte: $ppEnd }
          ) {
            edges {
              node {
                EndDate
              }
            }
          }
        }
      `,
      variables() {
        var ppStart = Math.min(...this.expendedHours.map((x) => x.minPP));
        var ppEnd = Math.max(...this.expendedHours.map((x) => x.maxPP));
        return {
          ppStart: ppStart,
          ppEnd: ppEnd,
        };
      },
      skip() {
        return !this.expendedHours.length;
      },
      update(data) {
        // convert to columns
        var cols = data.columns.edges.map((e) => {
          return {
            field: e.node.EndDate,
            label: e.node.EndDate,
            sortable: false,
            sticky: false,
            numeric: true,
          };
        });
        cols.unshift({
          field: "name",
          label: "Name",
          sortable: true,
          sticky: true,
          numeric: false,
        });
        return cols;
      },
      result() {
        this.fillInMissingHours();
      },
    },
  },
  data() {
    return {
      expendedHours: [],
      columns: [],
    };
  },
  methods: {
    tableHeight(list) {
      if (this.fullScreen) {
        return (
          (window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight) - 70
        );
      }
      var head = this.columns.length < 12 ? 65 : 90;
      var h2 = list.length > 5 ? 250 : head + 40 * list.length;
      var h = list.length === 0 ? 140 : h2;
      return h;
    },
    fillInMissingHours() {
      if (this.expendedHours.length === 0) return;
      if (this.columns.length === 0) return;

      // overwrite undefined hours with zero
      this.expendedHours.forEach((eh) => {
        this.columns.forEach((c) => {
          eh[c.field] = eh[c.field] || 0;
        });
      });
    },
  },
};
</script>
